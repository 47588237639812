.root {
}

.languageButton {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  font-size: 14px;
  color: var(--colorGrey700);

  &:hover {
    text-decoration: none;
  }
}

.languageButtonIconContainer {
  margin-right: 6px;
}

.languageButtonIcon {
  height: 22px;
  width: 22px;
}

.languageButtonText {
  /* Font */
  font-size: 14px;
  color: var(--colorGrey700);

  margin: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}
